<template>
  <NuxtImg
    v-if="data?.logo_url" 
    :src="data?.logo_url"
    format="webp"
    alt=""
    loading="lazy"
  />
</template>

<script setup lang="ts">
const { data } = useBootstrap();
</script>
